<template>
  <v-snackbar v-model="showSnackbar" :color="color" :timeout="timer" top right>
    <v-icon left>{{ icon }}</v-icon>
    {{ message }}
  </v-snackbar>
</template>


<script>
export default {
  name: 'toast',

  data() {
    return {
      showSnackbar: false,
      message: '',
      color: 'success',
      icon: 'mdi-check',
      timer: 1500
    }
  },

  methods: {
    show(data) {
      this.message = data.message || 'missing "message".'
      this.color = data.color || 'success'
      this.timer = data.timer || 1500
      this.icon = data.icon || 'mdi-check'
      this.showSnackbar = true
    }
  }
}
</script>

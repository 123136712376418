<template>
  <v-navigation-drawer fixed v-model="drawer" :mini-variant="mini" :disable-resize-watcher="true" :hide-overlay="true" permanent :width="350">
    <v-list>
      <v-list-item v-if="!mini">
        <v-img :width="150" :src="require(`../assets/${getLogoName()}`)"></v-img>
        <v-btn x-small depressed fab @click.stop="mini = !mini" class="ml-4">
          <v-icon>mdi-chevron-double-left</v-icon>
        </v-btn>
      </v-list-item>
      <v-list-item v-else class="px-2">
        <v-list-item-avatar class="ma-0">
          <v-btn :disabled="disable" small depressed icon @click.stop="mini = !mini">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item class="px-2" @click="routeTo('Dashboard')">
        <v-list-item-avatar color="main">
          <span class="white--text" style="justify-content: center">{{ accountName[0] }}</span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ accountName }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item v-for="nav in navLinks" :key="nav.title" :class="[$route.meta.family === nav.routeName ? 'active' : '']" @click="routeTo(nav.routeName)" link>
        <v-list-item-icon>
          <v-icon :color="nav.color">{{ nav.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ nav.title }}</v-list-item-title>
      </v-list-item>
    </v-list>

    <template v-if="!mini" v-slot:append>
      <!-- version -->
      <div class="version-nav">{{ version }}</div>

      <!-- logout -->
      <div class="pa-2">
        <v-btn @click="logoutHandler" block depressed :loading="loading">{{ translations.logout }}</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import {
  mdiCalendarMonth,
  mdiClipboardAccountOutline,
  mdiClockTimeElevenOutline,
  mdiTimetable,
  mdiAccountCogOutline,
  mdiOfficeBuildingMarker,
  mdiCalendarText,
  mdiAccountGroupOutline,
  mdiWarehouse,
  mdiDumpTruck,
  mdiImageFilterHdr,
  mdiCog
} from '@mdi/js'
import { mapState } from 'vuex'
import { translations } from '@/utils/common'
import { onLogout } from '@/vue-apollo'
import { getLogoFilename } from '@/utils/api/config.js'

export default {
  name: 'SideNav',

  props: {
    open: {
      type: Boolean,
      default: () => false
    },
    disable: {
      type: Boolean,
      default: () => false
    }
  },

  data: () => ({
    mini: true,
    drawer: true,
    loading: false,

    allNavLinks: [
      {
        title: translations.scheduleTitle,
        routeName: 'Schedule',
        icon: mdiCalendarMonth,
        color: 'main',
        capability: ['Activity Planner']
      },
      {
        title: translations.backOfficeTitle,
        routeName: 'BackOffice',
        icon: mdiClipboardAccountOutline,
        color: 'main',
        capability: ['Back Office']
      },
      {
        title: translations.clockInTitle,
        routeName: 'Reports',
        icon: mdiClockTimeElevenOutline,
        color: 'main',
        capability: ['Reports']
      },
      {
        title: translations.timeReportsTitle,
        routeName: 'TimeReports',
        icon: mdiTimetable,
        color: 'main',
        capability: ['Reports', 'Back Office']
      },
      {
        title: translations.manageAccounts,
        routeName: 'Accounts',
        icon: mdiAccountCogOutline,
        color: 'main',
        capability: ['Reports', 'Back Office']
      },
      {
        title: translations.categories,
        routeName: 'Categories',
        icon: mdiOfficeBuildingMarker,
        color: 'main',
        capability: ['Back Office']
      },
      {
        title: translations.longTermPlanner,
        routeName: 'Planner',
        icon: mdiCalendarText,
        color: 'main',
        capability: ['Long Term Planner']
      },
      {
        title: translations.squads,
        routeName: 'Squads',
        icon: mdiAccountGroupOutline,
        color: 'main',
        capability: ['Long Term Planner']
      },
      {
        title: translations.managePiles,
        routeName: 'Plants',
        icon: mdiImageFilterHdr,
        color: 'main',
        capability: ['Manage piles']
      },
      {
        title: translations.localUnitList,
        routeName: 'LocalUnits',
        icon: mdiWarehouse,
        color: 'main',
        capability: ['Manage piles']
      },
      {
        title: translations.manufacturerAndCarrier,
        routeName: 'ManufacturersAndCarriers',
        icon: mdiDumpTruck,
        color: 'main',
        capability: ['Manage piles']
      },
      {
        title: translations.admin,
        routeName: 'Admin',
        icon: mdiCog,
        color: 'main',
        capability: ['Update Report', 'Recalculate Warnings']
      }
    ]
  }),

  watch: {
    open() {
      this.mini = this.open
    },

    mini() {
      this.$emit('open', this.mini)
    }
  },

  methods: {
    routeTo(name) {
      if (this.disable) return
      this.$router.push({ name })
      this.$emit('open', true)
    },

    logoutHandler() {
      onLogout()
      this.$router.push({ name: 'Login' })
    },

    getLogoName() {
      return getLogoFilename()
    }
  },

  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo,
      version: state => 'v ' + state?.clientVersion?.clientVersion
    }),

    translations: () => translations,

    accountName() {
      if (!this.userInfo?.account) return ''
      return this.userInfo.account.name
    },

    email() {
      if (!this.userInfo?.account) return ''
      return this.userInfo.account.email
    },

    navLinks() {
      let links = []
      if (this.userInfo?.account?.capabilities) {
        this.allNavLinks.forEach(l => {
          const findUserCapabilities = element => l.capability.includes(element.name)
          const hasAdminCapabilities = this.userInfo.account.capabilities.some(findUserCapabilities)
          if (hasAdminCapabilities) {
            links.push(l)
          }
        })
      }
      return links
    }
  }
}
</script>

<style scoped>
.active {
  background: rgba(0, 0, 0, 0.3);
}

.version-nav {
  position: absolute;
  bottom: 45px;
  right: 12px;
  font-size: small;
  text-shadow: -1px -1px 2px white, 1px 1px 2px white;
}
</style>

<template>
  <v-app style="background-color: #f4f8ff" v-if="isLoaded">
    <side-nav v-if="!isLogin" :open="open" @open="val => (open = val)" :disable="disableNav"></side-nav>

    <schedule-copy-warning-dialog v-if="display" :display="display" @exitCopy="exitCopy"></schedule-copy-warning-dialog>
    <div :class="[isLogin ? '' : 'grid-main']" @click="open = true">
      <div v-if="!isLogin"></div>
      <router-view></router-view>
    </div>

    <!-- toast -->
    <toast ref="vtoast" />

    <!-- message dialog for new version reload page-->
    <message-dialog v-if="clientVersionShowModal" :show="clientVersionShowModal">
      <template v-slot:title>
        <div class="d-flex justify-center cursor-pointer" @click="reloadPageHandler">
          {{ translations.newVersionTitle }}
        </div>
      </template>

      <template v-slot:card-image>
        <div class="d-flex justify-center cursor-pointer pt-2" @click="reloadPageHandler">
          <v-img max-width="200" :src="require('./assets/happy-news.svg')"></v-img>
        </div>
      </template>

      <template v-slot:message>
        <div class="d-flex justify-center cursor-pointer pt-1" @click="reloadPageHandler">
          {{ translations.newVersionReload }}
        </div>
      </template>

      <template v-slot:card-action>
        <div class="d-flex justify-center cursor-pointer" style="width: 100%" @click="reloadPageHandler">
          <v-btn color="main" class="white--text">{{ translations.reloadPage }}</v-btn>
        </div>
      </template>
    </message-dialog>
  </v-app>
</template>

<script>
import SideNav from '@/components/SideNav.vue'
import ScheduleCopyWarningDialog from '@/components/ScheduleCopyWarningDialog.vue'
import MessageDialog from '@/components/MessageDialog.vue'
import toast from '@/components/Toast.vue'
import { loadConfig } from './utils/api/config.js'
import { eventBus, translations } from '@/utils/common'
import { mapState } from 'vuex'
import store from './store'
import { initCommonData } from './main'

export default {
  name: 'App',

  components: {
    SideNav,
    ScheduleCopyWarningDialog,
    toast,
    MessageDialog
  },

  data: () => ({
    open: false,
    disableNav: false,
    display: false,
    isLoaded: false,
    translations: translations
  }),

  computed: {
    ...mapState({
      clientVersionShowModal: state => state.clientVersion.clientVersionShowModal
    }),

    isLogin() {
      return this.$route.name === 'Login'
    }
  },

  created() {
    loadConfig(
      this.$vuetify,
      function() {
        this.isLoaded = true

        // if an account is logged in: init common data
        if (store.state.auth.userInfo.account) {
          initCommonData(store)
        }
      }.bind(this)
    )

    window.onstorage = () => {
      // when in copy mode
      const dateToCompare = JSON.parse(localStorage.getItem('dateToCompare'))
      if (!dateToCompare) {
        this.disableNav = false
        this.display = false
      }
      if (dateToCompare && window.location.pathname !== '/schedule') {
        this.disableNav = true
        this.display = true
      }

      if (dateToCompare) {
        this.disableNav = true
      }
    }
    eventBus.$on('should-disable-nav', data => (this.disableNav = data))
  },

  mounted() {
    // to use this toast in every page
    setTimeout(() => this.$root.vtoast = this.$refs.vtoast, 1000)

    const dateToCompare = JSON.parse(localStorage.getItem('dateToCompare'))

    if (window.location.pathname !== '/schedule') {
      if (dateToCompare) {
        this.disableNav = true
        this.display = true
      }
    } else {
      if (!dateToCompare) return
      if (this.$route.query.date) {
        this.scheduleDialog = false
        const dateParse = this.$route.query.date.split('-')
        if (dateParse.length === 3) {
          const [month, day, year] = dateParse
          const date = `${year}-${month}-${day}`
          if (date === dateToCompare.date || date === dateToCompare.dateCopy) {
            this.disableNav = true
            return
          } else {
            this.disableNav = true
            this.display = true
          }
        } else {
          this.disableNav = true
          this.display = true
        }
      } else {
        this.disableNav = true
      }
    }
  },

  methods: {
    exitCopy() {
      localStorage.removeItem('dateToCompare')
      this.display = false
      this.disableNav = false
    },

    reloadPageHandler() {
      location.reload(true)
    }
  }
}
</script>

<style>
/* global style */
.page-overflow {
  overflow: hidden;
}

.page-header-3 {
  height: 80px;

  margin-bottom: 20px;

  display: flex;
  justify-content: space-between;
}

.page-header-3 > * {
  width: 33%;
}

.page-header-filters {
  display: grid;
  grid-template-columns: repeat(2, 48%);
  column-gap: 2%;
  align-items: end;
}

.all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.all-center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-content {
  height: calc(100vh - 141px);

  border-radius: 4px !important;
}

.page-table .v-data-table__wrapper {
  height: calc(100vh - 200px);

  border-radius: 4px !important;
}

.page-table-no-footer .v-data-table__wrapper {
  height: calc(100vh - 141px);

  border-radius: 4px !important;
}

.page-table-custom {
  height: calc(100vh - 211px);

  border-radius: 4px !important;
  overflow-y: scroll;
}

/* app style */
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 10px;
}

.scroll-bar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  border-radius: 5px;
}

/* Track */
.scroll-bar::-webkit-scrollbar-track {
  background: #f6f8fa;
  border-radius: 5px;
}

/* Handle */
.scroll-bar::-webkit-scrollbar-thumb {
  background: #d0cfcf;
  border-radius: 5px;
}

/* Handle on hover */
.scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #888;
  border-radius: 5px;
}

.grid-main {
  display: grid;
  grid-template-columns: 56px auto;
}

.cursor-pointer {
  cursor: pointer;
}
</style>

import EmployeeStatusService from '@/service/employeeStatus/EmployeeStatusService'

export default {
  state: {
    employeeStatuses: [],
    employeeStatusesSelect: []
  },

  getters: {
    employeeStatuses(state) {
      return state.employeeStatuses
    },

    employeeStatusesSelect(state) {
      return state.employeeStatusesSelect
    }
  },

  actions: {
    initEmployeeStatus({ commit }) {
      EmployeeStatusService.findAll().then(payload => commit('update', payload))
    }
  },

  mutations: {
    update(state, payload) {
      // update employeeStatuses
      state.employeeStatuses = payload

      // update employeeStatuses for select
      state.employeeStatusesSelect = payload.map(es => ({
        value: es.id,
        text: es.description
      }))
    }
  }
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
import { AUTH_TOKEN } from '@/vue-apollo'
import { auth } from '../utils/api'
import store from '../store'

Vue.use(VueRouter)

const beforeEnter = async (to, from, next) => {
  // if the token or the store is not set then go to 'login'
  if (Cookies.get(AUTH_TOKEN) === undefined || !store?.state?.auth?.userInfo?.account) {
    await auth.logout()
    return next({ name: 'Login' })
  }

  // FIXME if the user doesn't have any capabilities then go to 'referTo'

  return next()
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: {
      family: 'Login'
    },
    component: () => import('../views/Login.vue')
  },

  {
    path: '/',
    name: 'Dashboard',
    meta: {
      family: 'Dashboard'
    },
    component: () => import('../views/Dashboard.vue'),
    beforeEnter: beforeEnter
  },
  {
    path: '/refer-to',
    name: 'ReferTo',
    meta: {
      family: 'Dashboard'
    },
    component: () => import('../views/ReferTo.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/schedule',
    name: 'Schedule',
    meta: {
      family: 'Schedule'
    },
    component: () => import('../views/Schedule.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/back-office',
    name: 'BackOffice',
    meta: {
      family: 'BackOffice'
    },
    component: () => import('../views/BackOffice/BackOfficeActivityRequest.vue'),
    beforeEnter: beforeEnter
  },
  {
    path: '/back-office-new',
    name: 'BackOfficeNew',
    meta: {
      family: 'BackOffice'
    },
    component: () => import('../views/ActivityRequest/ActivityRequest.vue'),
    beforeEnter: beforeEnter
  },
  {
    path: '/back-office-vehicle',
    name: 'BackOfficeVehicle',
    meta: {
      family: 'BackOffice'
    },
    component: () => import('../views/BackOffice/BackOfficeVehicleActivity.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/clock-in',
    name: 'Reports',
    meta: {
      family: 'Reports'
    },
    component: () => import('../views/Reports.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/time-reports',
    name: 'TimeReports',
    meta: {
      family: 'TimeReports'
    },
    component: () => import('../views/TimeReports.vue'),
    beforeEnter: beforeEnter
  },
  {
    path: '/employee-time-report',
    name: 'EmployeeTimeReport',
    meta: {
      family: 'TimeReports'
    },
    component: () => import('../views/EmployeeTimeReport.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/accounts',
    name: 'Accounts',
    meta: {
      family: 'Accounts'
    },
    component: () => import('../views/Accounts.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/categories',
    name: 'Categories',
    meta: {
      family: 'Categories'
    },
    component: () => import('../views/Categories.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/planner',
    name: 'Planner',
    meta: {
      family: 'Planner'
    },
    component: () => import('../views/PlannerView/PlannerView.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/squads',
    name: 'Squads',
    meta: {
      family: 'Squads'
    },
    component: () => import('../views/SquadsView/SquadsView.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/plants',
    name: 'Plants',
    meta: {
      family: 'Plants'
    },
    component: () => import('../views/Plants'),
    beforeEnter: beforeEnter
  },
  {
    path: '/piles',
    name: 'Piles',
    meta: {
      family: 'Plants'
    },
    component: () => import('../views/Piles.vue'),
    beforeEnter: beforeEnter
  },
  {
    path: '/pile-movements',
    name: 'PileMovements',
    meta: {
      family: 'Plants'
    },
    component: () => import('../views/PileMovements.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/local-units',
    name: 'LocalUnits',
    meta: {
      family: 'LocalUnits'
    },
    component: () => import('../views/LocalUnits.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/manufacturers-and-carriers',
    name: 'ManufacturersAndCarriers',
    meta: {
      family: 'ManufacturersAndCarriers'
    },
    component: () => import('../views/ManufacturersAndCarriers.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/admin',
    name: 'Admin',
    meta: {
      family: 'Admin'
    },
    component: () => import('../views/Admin.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = router.push
router.push = function push(location) {
  return originalPush.call(this, location).catch(e => e)
}

export default router

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import activityRequestDayParts from './modules/ActivityRequestDayPartStore'
import activityRequestStatus from './modules/ActivityRequestStatusStore'
import auth from './modules/auth'
import clientVersion from './modules/ClientVersionStore'
import clockIns from './modules/clockIns'
import date from './modules/date'
import employees from './modules/employees'
import employeeStatus from './modules/EmployeeStatusStore'
import suppliers from './modules/suppliers'
import vehicles from './modules/vehicles'
import draggables from './modules/draggables'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    activityRequestDayParts,
    activityRequestStatus,
    auth,
    clientVersion,
    clockIns,
    date,
    employees,
    employeeStatus,
    suppliers,
    vehicles,
    draggables
  },

  plugins: [
    createPersistedState({
      key: 'vuex-admin',
      paths: [
        'activityRequestDayParts',
        'activityRequestStatus',
        'auth',
        'clientVersion',
        'employeeStatus'
      ]
    })
  ]
})

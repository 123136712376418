import { translations } from '@/utils/common'

export default {
  state: {
    // FIXME: retrieve activityRequestStatuses from db
    activityRequestStatuses: [{
      value: 'PENDING',
      text: translations['PENDING'.toLowerCase()]
    }, {
      value: 'APPROVED',
      text: translations['APPROVED'.toLowerCase()]
    }, {
      value: 'REJECTED',
      text: translations['REJECTED'.toLowerCase()]
    }],

    activityRequestStatusesColors: {
      'PENDING': {
        text: '#262626',
        background: '#FFFCDE'
      },
      'APPROVED': {
        text: '#2A6A2D',
        background: '#E7F5E9'
      },
      'REJECTED': {
        color: '#D60011',
        background: '#FFEAEC'
      }
    }
  },

  getters: {
    activityRequestStatuses(state) {
      return state.activityRequestStatuses
    },

    activityRequestStatusesColors(state) {
      return state.activityRequestStatusesColors
    }
  }
}

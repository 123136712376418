const translations = {
  paginationPerPage: 'per pagina',
  paginationOf: 'di',
  reload: 'Aggiorna',
  acceptOrDeclineRequest: 'Accetta o rifiuta la richiesta',
  active: 'Attivo',
  activityHasBeenSaved: 'L\'attività è stata salvata',
  updateSuccess: 'Aggiornamento avvenuto con successo',
  deletedSuccess: 'Cancellazione avvenuta con successo',
  actions: 'Azioni',
  activityPlanner: 'Pianificatore di attività',
  activityType: 'Tipo di Attività',
  addFilter: 'aggiungi filtro',
  additionalInfo: 'Note',
  addNewPassword: 'nuova password',
  addProject: '+',
  allClockInsConfirmed: 'Tutte le timbrature sono state confermate',
  allowance: 'Indennità',
  appForAdminOnly: 'Questa app è solo per amministratori',
  approve: 'Approva',
  approved: 'Approvato',
  areYouSureYouWantToConfirmTimes: 'Così facendo non potrai più aggiungere o modificare altre timbrature',
  backOffice: 'Back Office',
  backOfficeTitle: 'Back Office',
  badCredentials: 'Username o password non validi',
  userIsDisabled: 'L\'utente è disabilitato',
  bituminousConglomerate: 'Conglomerato bituminoso',
  cancel: 'Indietro',
  cannotDeleteCategory: 'Impossibile cancellare la categoria selezionata. Verificare che non ci siano timbrature o pianificature associate ad essa',
  cannotDeleteLocalUnit: 'Impossibile cancellare l\'unità locale selezionata. Verificare che non ci siano cumuli associate ad essa',
  cannotDeleteManufacturer: 'Impossibile cancellare il produttore/trasportatore. Verificare che non ci siano cumuli o movimenti associati ad esso',
  carrier: 'Trasportatore',
  categories: 'Impianti',
  category: 'Impianto',
  certificate: 'Certificato analisi',
  certificates: 'Certificati',
  certificateColumn: 'Certificato',
  clockInTitle: 'Timbrature',
  close: 'Annulla',
  closePile: 'Chiudi',
  code: 'Codice interno',
  confirm: 'Conferma',
  confirmAsIs: 'conferma così com\'è',
  confirmDeactivation: 'Chiudere Cumulo?',
  confirmDeletion: 'Cancellare Cumulo?',
  confirmed: 'Confermati',
  confirmedAndNot: 'Confermati e non',
  confirmTodaysTimes: 'conferma tutte le timbrature',
  copyFromPreviousDay: 'da precedente',
  copyWarning: `Seems like there 2 schedules in the copy phase. If you would like to continue through the application please close the windows or click the button below to exit out of the copy phase.`,
  create: 'Creare',
  customDates: 'Date Personalizzate',
  dashboardTitle: 'Dashboard',
  date: 'Data',
  dateFilter: 'Filtra per Data',
  dateFrom: 'Dalla data',
  dateToCopy: 'Data sorgente',
  dateToProgram: 'Data destinazione',
  dayOff: 'Giorno libero',
  dayPart: 'Parte del giorno',
  ddtRegistryId: 'N° DDT',
  default: 'Predefinito',
  deny: 'Rifiuta',
  delete: 'Cancella',
  deleteError: 'Errore di cancellazione',
  destination: 'Destinazione',
  description: 'Descrizione',
  didDriveVan: 'Hai guidato un furgone?',
  didWorkOnProjectOrCategory: 'Hai lavorato a una commessa o in un impianto?',
  download: 'scarica',
  downloadCertificates: 'Scarica certificati',
  downloadDdt: 'Scarica DDT',
  driving: 'Guida',
  droveHours: 'Ore alla guida',
  droveOtherVehicles: 'Hai fatto rifornimento con un altro veicolo?',
  edit: 'Modifica',
  editActivities: 'Modifica Attività',
  employee: 'Addetto',
  employeeEndDate: 'Data di fine del dipendente',
  goTo: 'vai a',
  employeeRequest: 'Richieste dipendenti',
  employees: 'Addetto',
  employeesAvailable: 'Addetti disponibili',
  employeesMissingClockout: 'Ai seguenti dipendenti manca un timestamp di scadenza',
  exitFromCopy: 'Esci dalla copia',
  extraOrdinaryHours: 'Straord (ore)',
  filterForEmployee: 'Dipendente',
  filterForProject: 'Commessa',
  filterForTeam: 'Team',
  from: 'dal',
  fromToDateError: 'La data da non può essere successiva alla data a',
  fullDay: '8',
  gasLiters: 'Gas rifornito (litri)',
  generateReport: 'Genera rapporto',
  getReport: 'ottenere rapporto',
  goToEmployeeApp: 'Vai all\'app dei dipendenti',
  hello: 'Ciao',
  hours: 'Ore',
  idLetter: 'Lettera identificativa',
  ifDriver: 'Se autista',
  in: 'Entrata',
  individualReport: 'Rapporto individuale',
  individualReportShort: 'Singolo',
  allEmployeeReport: 'Indietro alla vista globale',
  allEmployeeReportShort: 'Tutti',
  insufficientTonsError: 'Errore: tonnellate insufficienti',
  layoff: 'Cassa integrazione',
  layoffHours: 'Cassa integrazione (ore)',
  letter: 'Lett. identif.',
  license: 'Targa',
  link: 'link',
  load: 'Carico',
  location: 'Posizione',
  localUnit: 'Unità Locale',
  localUnitList: 'Anagrafica Unità Locali',
  localUnitProject: 'Unità Locale/Commessa',
  login: 'Accedi',
  logout: 'Logout',
  lunchBreak: 'Pausa pranzo',
  m3: 'm³',
  mainProject: 'Principale',
  maintenance: 'Manutenzione',
  maintenanceVehicleCode: 'Codice mezzo manutenzione',
  admin: 'Admin',
  manageAccounts: 'Account',
  managePiles: 'Gestione cumuli',
  managingTimeCardFor: 'Timbratura per',
  manufacturer: 'Produttore',
  manufacturerAndCarrier: 'Anagrafica Produttore/Trasportatore',
  milling: 'Fresatura',
  millingHours: 'Ore Fresatura',
  model: 'Modello',
  monthly: 'mese',
  morning: 'Mattina',
  afternoon: 'Pomeriggio',
  name: 'Nome',
  newActivity: 'Nuova Attività',
  newCategory: 'Nuovo Impianto',
  newLocalUnit: 'Nuova Unità Locale',
  newManufacturerCarrier: 'Nuovo Produttore/Trasportatore',
  newPile: 'Crea nuovo cumulo',
  newRequest: 'Nuova Richiesta',
  nightHours: 'Ore notturne',
  no: 'No',
  noBreak: '0 continuato',
  noDataFound: 'Non ci sono dati per i filtri selezionati',
  loading: 'Caricamento in corso...',
  noCarrierFoundWThatName: 'Nessun trasportatore trovato con quel nome',
  noEmployeesFoundWThatName: 'Nessun dipendente trovato con quel nome',
  noItemsFound: 'Nessun elemento trovato',
  noLocaUnitFoundWThatName: 'Nessuna Unita\' locale trovata con quel nome',
  noManufacturerFoundWThatName: 'Nessun produttore trovato con quel nome',
  noPartsAvailable: 'Nessuna parte disponibile per questa commessa',
  noProjectsWithThatName: 'Nessuna commessa valida per il testo inserito',
  noSchedulesCreatedOnDate: 'Nessuna pianificazione è stata creata in questa data',
  notAvailable: 'Non disponibile',
  notConfirmed: 'Non confermati',
  notes: 'Note',
  noVehiclesWithThatCode: 'Nessun veicolo con quel codice interno',
  number: 'n.',
  oneHour: '1 ora',
  operations: 'Operazioni',
  optionalNoteForRequest: 'Aggiungi una nota (facoltativo)',
  ordinaryHours: 'Ordinarie (ore)',
  out: 'Uscita',
  overlappingDatesError: 'Impossibile salvare l\'attività con date sovrapposte',
  part: 'Parte',
  password: 'Password',
  pending: 'In Attesa',
  pileDetail: 'Cumuli - Impianto di ',
  piles: 'Cumuli',
  plantDestination: 'Destinatario',
  plants: 'Impianti',
  pleaseCheckBackInMinutes: 'Per favore ricontrolla tra un paio di minuti',
  pleaseReferToEmployeeApp: 'si prega di fare riferimento all\'app dei dipendenti!',
  print: 'stampa',
  project: 'Commessa',
  projects: 'Commesse',
  quanityExpected: 'Quintali previsti',
  quantity: 'Quantità',
  referenceTurn: 'Turno di riferimento',
  refuel: 'Rifornimento',
  registryId: 'N° Registro',
  rejected: 'Negato',
  remainder: 'Residuo',
  remainderWithUnload: 'Residuo (Scarico)',
  rememberMe: 'Ricorda il nome utente',
  removeProject: '-',
  removeSchedule: 'rimuovi commessa',
  repair: 'Riparazione',
  request: 'Richiesta',
  handleRequest: 'Gestisci richiesta',
  changeRequest: 'Cambia stato richiesta',
  requestType: 'Tipo di richiesta',
  saveAllClockIns: 'Vuoi confermare tutte le timbrature?',
  saveError: 'Errore di salvataggio',
  saveNewPile: 'Crea Cumulo',
  createRecord: 'Crea nuovo record',
  scheduleTitle: 'Pianificazione',
  searchEmployee: 'search employee',
  search: 'ricerca',
  searchProject: 'Ricerca commessa',
  select: 'Seleziona',
  selectEmployee: 'Seleziona Dipendente',
  selectEmployeeForReport: 'Seleziona il dipendente per visualizzare il rapporto',
  selectReportType: 'Seleziona il tipo di rapporto',
  selectStartDate: 'Seleziona Data di Inizio',
  selectVehicle: 'Seleziona Veicolo',
  shiftWillEndNextDay: 'L\'attuale turno di lavoro terminerà il giorno successivo',
  special: 'SPECIAL',
  specialv2: 'SPECIALv2',
  standard: 'STANDARD',
  somethingWentWrong: 'Qualcosa è andato storto',
  status: 'Stato',
  statusFilter: 'Filtra per Stato',
  submit: 'Inizia',
  subpart: 'Sottoparte',
  suggestedProjects: 'Commesse suggerite',
  suppliersAvailable: 'Fornitori disponibili',
  t800: 'T800',
  thirtyMinutes: '30 minuti',
  time: 'Ora',
  timeReport: 'rapporto temporale',
  timeReportsTitle: 'Report ore lavorate',
  timesToCompare: 'Orari da Confrontare',
  to: 'al',
  toDate: 'Alla data',
  tons: '(tons)',
  total: 'Totale',
  totalAllowanceHours: 'Ore di indennità totale',
  totalAllowanceType: 'Tipo di indennità di ore totali',
  totalExtraOrdinaryHours: 'Tot straord (ore)',
  totalHours: 'Ore totali',
  totalOrdinaryHours: 'Tot ordinarie (ore)',
  twoDatesSelected: '2 date selezionate',
  type: 'Genere',
  typology: 'Tipologia',
  typeVehicleCode: 'Digita il codice interno',
  unload: 'Scarico',
  unloadedFrom: 'Scarico da',
  reset: 'Reset',
  updateCertificate: 'aggiorna certificato',
  updatePile: 'Modifica cumulo',
  updateRecord: 'Aggiorna record',
  uploadCertificate: 'carica certificato',
  uploadCertificateTooltip: 'Carica Certificato',
  usedVan: 'Furgone usato',
  userHasBeenUpdated: 'l\'utente è stato aggiornato',
  username: 'Nome utente',
  email: 'Mail',
  resetPassword: 'Reset password',
  resetPasswordHelp: 'Inserisci una password e clicca reset. L\'utente dovrà cambiare la password al prossimo login.',
  passwordReset: 'Password impostata',
  vacancyTime: 'Ferie / Assenza',
  van: 'Furgone',
  vanCode: 'Numero Furgone',
  vans: 'Furgone',
  vehicle: 'Veicolo',
  vehicleCode: 'Codice autocarro',
  vehicleInformation: 'Informazioni sul Veicolo',
  vehicleInRepair: 'Mezzi in riparazione',
  vehicleNotInserted: 'Veicolo non inserito',
  vehicleRequest: 'Attività veicolo',
  vehicles: 'Mezzo',
  vehiclesAvailable: 'Mezzi disponibili',
  warningMissingTimeStamp: 'Avviso timbrature mancanti',
  weekly: 'sett.',
  workHours: 'Ore lavorate',
  yearly: 'anno',
  yes: 'Sì',
  youCanChangeStatusFrom: 'Puoi cambiare lo stato da',
  youCanChangeStatusTo: 'a',
  lunchTime0: '0 continuato',
  lunchTime30: '30 minuti',
  lunchTime60: '1 ora',
  lunchTime90: '1 ora 30 minuti',
  lunchTime120: '2 ore',
  reportEmployeeTimeReport: 'Addetti',
  reportEmployeeTimeReportShort: 'Addetti',
  reportVehicleAllocationOperators: 'Attrezzature da operatori',
  reportVehicleAllocationShortOperators: 'Attrezzature da operatori',
  reportVehicleAllocationDrivers: 'Attrezzature da autisti',
  reportVehicleAllocationShortDrivers: 'Attrezzature',
  reportVehicleGasUsage: 'Rifornimento gas',
  reportLongTermForeman: 'Pianificazione caposquadra',
  dpiVerification: 'Verifica DPI',
  materialUsed: 'Prodotto steso',
  tappeto: 'Tappeto',
  binder: 'Binder',
  base: 'Base',
  cementato: 'Cementato',
  stabilizzato: 'Stabilizzato',
  asphaltTemperature: 'Temperatura asfalto',
  environmentTemperature: 'Temperatura ambiente',
  manHolesVerification: 'Controllo chiusini e caditoie',
  other: 'Altro',
  squads: 'Squadre',
  longTermPlanner: 'Pianificatore a lungo termine',
  changeStatus: 'Cambia Stato',
  addTimeForEmployee: 'Aggiungi tempo per il dipendente',
  startDate: 'Data Inizio',
  endDate: 'Data Fine',
  removeSquad: 'rimuovi squadra',
  noAssignedEmployeesToSquad: 'Nessun dipendente assegnato in questa squadra',
  newSquad: 'Nuova Squadra',
  whatNameForSquad: 'Che nome vorresti dare alla nuova squadra!',
  createNewSquad: 'crea nuova squadra',
  employeesPlural: 'Addetti',
  filterBySquadName: 'Filtra per nome della squadra',
  dateEndOfContract: 'Data fine lavori da contratto',
  totalAllocatedDays: 'Totale giorni allocati',
  squad: 'Squadra',
  updateNotes: 'aggiorna note',
  updateNotesFor: 'Note di aggiornamento per',
  typeSquadName: 'Digitare il nome della squadra',
  unableToUpdateTimeline: 'Impossibile aggiornare la sequenza temporale in questo momento',
  updateError: 'Errore di aggiornamento',
  timelineDateForEmployee: 'La data di pianificazione per il dipendente',
  exceedsEndDate: 'supera la data di fine rapporto',
  selectProductionCenter: 'Selezionare il centro di produzione',
  activeProductionCenters: 'Centri di produzione attivi',
  findProject: 'Trova progetto',
  changeProjectOrder: 'Cambia ordine di progetto',
  typeSupplierName: 'Digitare il nome del fornitore',
  noSupplierFoundWThatName: 'Nessun fornitore trovato con quel nome',
  addTimeForSupplier: 'Aggiungere tempo per il fornitore',
  suppliers: 'Fornitori',
  expand: 'Espandi',
  collapse: 'Riduci',
  noResultsFound: 'Nessun risultato trovato',
  changeForemanCategory: 'Caposquadra',
  categoryField: 'Categoria',
  sortField: 'Oridinamento',
  selectCategory: 'Seleziona categoria',
  saved: 'Salvato',
  updated: 'Aggiornato',
  security: 'Sicurezza',
  enabled: 'Abilitato',
  securityType: 'Tipologia di riscontro',
  securityNote: 'Nota del report',
  securityType0: 'cantiere',
  securityType1: 'mezzi/attrezzature',
  securityType2: 'DPI',
  securityType3: 'mancato infortunio',
  teamType1: 'Autisti',
  teamType2: 'Operatori',
  cloneScheduleTitle: 'Seleziona range di date',
  newVersionTitle: 'È disponibile una nuova versione di TOM!',
  newVersionReload: 'Per favore clicca qui sotto e verifica l\'ultima operazione eseguita.',
  reloadPage: 'Aggiorna versione',
  preparation: 'Preparazione',
  preparationLong: 'Preparazione piano di posa asfalto'
}

export default translations

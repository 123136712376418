import axios from 'axios'
import conf from './conf'
import { updateVuetifyLightTheme } from '../../plugins/vuetify.js'
import { translations } from '../common'
import store from '../../store'

const configData = {}

function getConfigResponse() {
  return axios.get(`${conf.value('VUE_APP_GRAPHQL_HTTP')}config`)
}

export function loadConfig(vuetifyInstance, callback) {
  if (Object.keys(configData).length === 0) {
    getConfigResponse().then(success => {
      // check client version
      store.dispatch('clientVersionCheck', success.headers['tom-client-version'])

      // set config
      const colors = JSON.parse(success.data.filter(prop => prop.key === 'colors')[0].value)
      updateVuetifyLightTheme(vuetifyInstance, colors)

      configData.logoFilename = success.data.filter(prop => prop.key === 'logoFilename')[0].value

      configData.lunchTimes = JSON.parse(success.data.filter(prop => prop.key === 'lunchTimeJSON')[0].value)
      configData.lunchTimes.forEach(minuteValue => {
        minuteValue.text = translations[minuteValue.label]
      })

      configData.securityTypes = JSON.parse(success.data.filter(prop => prop.key === 'securityTypes')[0].value)
      configData.securityTypes.forEach(type => {
        type.label = translations[type.label]
      })

      configData.questions = JSON.parse(success.data.filter(prop => prop.key === 'questionsJSON')[0].value)

      configData.mobileDriverShowDDTButton = success.data.filter(prop => prop.key === 'mobileDriverShowDDTButton')[0].value === 'true'

      configData.exportReports = JSON.parse(success.data.filter(prop => prop.key === 'exportReports')[0].value)

      configData.availableMaterials = [{ value: 'Tappeto' }, { value: 'Binder' }, { value: 'Base' }, { value: 'Cementato' }, { value: 'Stabilizzato' }]
      configData.availableMaterials.forEach(mat => {
        mat.text = translations[mat.value.toLowerCase()]
      })

      configData.foremanCategories = JSON.parse(success.data.filter(prop => prop.key === 'foremanCategories')[0].value)

      configData.pileTypes = JSON.parse(success.data.filter(prop => prop.key === 'pileTypes')[0].value)

      configData.teamTypes = JSON.parse(success.data.filter(prop => prop.key === 'teamTypes')[0].value)

      configData.fixedProjectScheduleHeight = success.data.find(prop => prop.key === 'fixedProjectScheduleHeight').value === 'true'

      configData.plannerResourcesSelectedOnBottom = success.data.find(prop => prop.key === 'plannerResourcesSelectedOnBottom').value === 'true'

      configData.draggableSchedule = success.data.find(prop => prop.key === 'draggableSchedule').value === 'true'

      configData.useBiggerFontInSchedule = success.data.find(prop => prop.key === 'useBiggerFontInSchedule').value === 'true'

      configData.showProjectDescriptionInSchedule = success.data.find(prop => prop.key === 'showProjectDescriptionInSchedule').value === 'true'

      configData.closeProgramHeader = success.data.find(prop => prop.key === 'closeProgramHeader').value === 'true'

      callback()
    })
  } else {
    callback()
  }
}

export function getLogoFilename() {
  return configData.logoFilename
}

export function getLunchTimes() {
  return configData.lunchTimes
}

export function getSecurityTypes() {
  return configData.securityTypes
}

export function getOperatorQuestionsArray() {
  return configData.questions.operator
}

export function getOperatorForemanQuestionsArray() {
  return configData.questions.operatorForeman
}

export function getDriverQuestionsArray() {
  return configData.questions.driver
}

export function getMobileDriverShowDDTButton() {
  return configData.mobileDriverShowDDTButton
}

export function getExportReports() {
  return configData.exportReports
}

export function getMaterials() {
  return configData.availableMaterials
}

export function getForemanCategories() {
  return configData.foremanCategories
}

export function getPileTypes() {
  return configData.pileTypes
}

export function getTeamTypes() {
  return configData.teamTypes
}

export function getFixedProjectScheduleHeight() {
  return configData.fixedProjectScheduleHeight
}

export function getPlannerResourcesSelectedOnBottom() {
  return configData.plannerResourcesSelectedOnBottom
}

export function getDraggableSchedule() {
  return configData.draggableSchedule
}

export function getUseBiggerFontInSchedule() {
  return configData.useBiggerFontInSchedule
}

export function getShowProjectDescriptionInSchedule() {
  return configData.showProjectDescriptionInSchedule
}

export function getCloseProgramHeader() {
  return configData.closeProgramHeader
}

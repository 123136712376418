const initialState = () => {
  return {
    userInfo: {}
  }
}

const state = initialState()
const getters = {}
const actions = {
  setUserInfo: ({ commit }, payload) => {
    commit('mutateUserInfo', payload)
    return
  }
}
const mutations = {
  mutateUserInfo: (state, payload) => {
    if (payload && payload.account) {
      const hasUserCapability = payload.account.capabilities?.some(e => e.name.toLowerCase() === 'user')
      payload.isAdmin = payload.account.capabilities?.length > (hasUserCapability ? 1 : 0)
    }

    state.userInfo = payload
  }
}

export default { state, getters, actions, mutations }
